// Generated by purs version 0.15.8
import * as Control_Bind from "../Control.Bind/index.js";
import * as Effect from "../Effect/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var GeneralError = /* #__PURE__ */ (function () {
    function GeneralError(value0) {
        this.value0 = value0;
    };
    GeneralError.create = function (value0) {
        return new GeneralError(value0);
    };
    return GeneralError;
})();
var AuthError = /* #__PURE__ */ (function () {
    function AuthError(value0) {
        this.value0 = value0;
    };
    AuthError.create = function (value0) {
        return new AuthError(value0);
    };
    return AuthError;
})();
var BackendError = /* #__PURE__ */ (function () {
    function BackendError(value0) {
        this.value0 = value0;
    };
    BackendError.create = function (value0) {
        return new BackendError(value0);
    };
    return BackendError;
})();
var redirectExternal = function (url) {
    return bind(bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.setHref(url));
};
var printError = function (v) {
    if (v instanceof GeneralError) {
        return "GeneralError(" + (v.value0 + ")");
    };
    if (v instanceof AuthError) {
        return "AuthError(" + (v.value0 + ")");
    };
    if (v instanceof BackendError) {
        return "BackendError(" + (v.value0 + ")");
    };
    throw new Error("Failed pattern match at Common (line 15, column 1 - line 15, column 39): " + [ v.constructor.name ]);
};
var baseUrl = function __do() {
    var host = bind(bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.host)();
    var protocol = bind(bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.protocol)();
    return protocol + ("//" + host);
};
export {
    GeneralError,
    AuthError,
    BackendError,
    printError,
    redirectExternal,
    baseUrl
};
