// Generated by purs version 0.15.8
import * as Common from "../Common/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Error from "../Data.Argonaut.Decode.Error/index.js";
import * as Data_Argonaut_Decode_Parser from "../Data.Argonaut.Decode.Parser/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
import * as Web_Storage_Storage from "../Web.Storage.Storage/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var token_typeIsSymbol = {
    reflectSymbol: function () {
        return "token_type";
    }
};
var scopeIsSymbol = {
    reflectSymbol: function () {
        return "scope";
    }
};
var refresh_tokenIsSymbol = {
    reflectSymbol: function () {
        return "refresh_token";
    }
};
var expires_inIsSymbol = {
    reflectSymbol: function () {
        return "expires_in";
    }
};
var access_tokenIsSymbol = {
    reflectSymbol: function () {
        return "access_token";
    }
};
var decodeJson = /* #__PURE__ */ Data_Argonaut_Decode_Class.decodeJson(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)(token_typeIsSymbol)()())(scopeIsSymbol)()())(refresh_tokenIsSymbol)()())(expires_inIsSymbol)()())(access_tokenIsSymbol)()())());
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil)(token_typeIsSymbol)())(scopeIsSymbol)())(refresh_tokenIsSymbol)())(expires_inIsSymbol)())(access_tokenIsSymbol)())());
var bind1 = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var NotLoggedIn = /* #__PURE__ */ (function () {
    function NotLoggedIn() {

    };
    NotLoggedIn.value = new NotLoggedIn();
    return NotLoggedIn;
})();
var LoggedIn = /* #__PURE__ */ (function () {
    function LoggedIn(value0) {
        this.value0 = value0;
    };
    LoggedIn.create = function (value0) {
        return new LoggedIn(value0);
    };
    return LoggedIn;
})();
var Check = /* #__PURE__ */ (function () {
    function Check() {

    };
    Check.value = new Check();
    return Check;
})();
var Login = /* #__PURE__ */ (function () {
    function Login() {

    };
    Login.value = new Login();
    return Login;
})();
var render = function (v) {
    if (v instanceof NotLoggedIn) {
        return Halogen_HTML_Elements.a([ Halogen_HTML_Properties.id("discord-button"), Halogen_HTML_Events.onClick(Data_Function["const"](Login.value)) ])([ Halogen_HTML_Elements.img([ Halogen_HTML_Properties.id("discord-button-image"), Halogen_HTML_Properties.src("./img/discord.png") ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("discord-button-text") ])([ Halogen_HTML_Core.text("Login") ]) ]);
    };
    if (v instanceof LoggedIn) {
        return Halogen_HTML_Elements.a([ Halogen_HTML_Properties.id("discord-button") ])([ Halogen_HTML_Elements.img([ Halogen_HTML_Properties.id("discord-button-image"), Halogen_HTML_Properties.src("./img/discord.png") ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("discord-button-text") ])([ Halogen_HTML_Core.text("Open Console") ]) ]);
    };
    throw new Error("Failed pattern match at Signin (line 58, column 1 - line 58, column 57): " + [ v.constructor.name ]);
};
var initialState = /* #__PURE__ */ (function () {
    return Data_Function["const"](NotLoggedIn.value);
})();
var getCodeFromUrl = function __do() {
    var q = bind(bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.search)();
    return Data_Either.hush(Routing_Duplex.parse(Routing_Duplex.param("code"))(q));
};
var discordTokenKey = "discord-token";
var getTokenFromStorage = function __do() {
    var maybeTokenString = bind(bind(Web_HTML.window)(Web_HTML_Window.localStorage))(Web_Storage_Storage.getItem(discordTokenKey))();
    if (maybeTokenString instanceof Data_Maybe.Nothing) {
        return new Data_Either.Left(new Common.GeneralError("No token"));
    };
    if (maybeTokenString instanceof Data_Maybe.Just) {
        var v = Data_Argonaut_Decode_Parser.parseJson(maybeTokenString.value0);
        if (v instanceof Data_Either.Left) {
            return new Data_Either.Left(new Common.GeneralError(Data_Argonaut_Decode_Error.printJsonDecodeError(v.value0)));
        };
        if (v instanceof Data_Either.Right) {
            var v1 = decodeJson(v.value0);
            if (v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(new Common.GeneralError(Data_Argonaut_Decode_Error.printJsonDecodeError(v1.value0)));
            };
            if (v1 instanceof Data_Either.Right) {
                return new Data_Either.Right(v1.value0);
            };
            throw new Error("Failed pattern match at Signin (line 89, column 21 - line 92, column 50): " + [ v1.constructor.name ]);
        };
        throw new Error("Failed pattern match at Signin (line 85, column 13 - line 92, column 50): " + [ v.constructor.name ]);
    };
    throw new Error("Failed pattern match at Signin (line 81, column 5 - line 92, column 50): " + [ maybeTokenString.constructor.name ]);
};
var saveTokenToStorage = function (token) {
    return function __do() {
        bind(bind(Web_HTML.window)(Web_HTML_Window.localStorage))(Web_Storage_Storage.setItem(discordTokenKey)(Data_Argonaut_Core.stringify(encodeJson(token))))();
        return new Data_Either.Right(Data_Unit.unit);
    };
};
var discordClientId = "1081168959941918801";
var discordLoginUrl = function (redirectUrl) {
    return "https://discord.com/api/oauth2/authorize?client_id=" + (discordClientId + ("&redirect_uri=" + (redirectUrl + "&response_type=code&scope=identify%20email")));
};
var baseRedirectUrl = function __do() {
    var url = Common.baseUrl();
    return url + "/redeem";
};
var handleAction = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return function (v) {
        if (v instanceof Check) {
            return bind1(liftEffect(getTokenFromStorage))(function (eitherToken) {
                return modify_(function (v1) {
                    return Data_Either.either(Data_Function["const"](NotLoggedIn.value))(LoggedIn.create)(eitherToken);
                });
            });
        };
        if (v instanceof Login) {
            return bind1(liftEffect(function __do() {
                var redirectUrl = baseRedirectUrl();
                return Common.redirectExternal(discordLoginUrl(redirectUrl))();
            }))(function () {
                return modify_(identity);
            });
        };
        throw new Error("Failed pattern match at Signin (line 47, column 1 - line 47, column 101): " + [ v.constructor.name ]);
    };
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadEffect),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(Check.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
var _proxy = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
export {
    NotLoggedIn,
    LoggedIn,
    Check,
    Login,
    _proxy,
    component,
    initialState,
    handleAction,
    render,
    discordTokenKey,
    discordClientId,
    getTokenFromStorage,
    discordLoginUrl,
    baseRedirectUrl,
    getCodeFromUrl,
    saveTokenToStorage
};
