// Generated by purs version 0.15.8
import * as $foreign from "./foreign.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
var peek = /* #__PURE__ */ (function () {
    return $foreign.peekImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
})();
export {
    new,
    poke,
    delete
} from "./foreign.js";
export {
    peek
};
