// Generated by purs version 0.15.8
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Aff_Util from "../Halogen.Aff.Util/index.js";
import * as Halogen_Query from "../Halogen.Query/index.js";
import * as Halogen_VDom_Driver from "../Halogen.VDom.Driver/index.js";
import * as Kernel from "../Kernel/index.js";
import * as Routes from "../Routes/index.js";
import * as Routing_PushState from "../Routing.PushState/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var component = /* #__PURE__ */ Kernel.component(Effect_Aff.monadEffectAff)(Effect_Aff_Class.monadAffAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var main = /* #__PURE__ */ Halogen_Aff_Util.runHalogenAff(/* #__PURE__ */ bind(Halogen_Aff_Util.awaitBody)(function (body) {
    return bind(Halogen_VDom_Driver.runUI(component)(Data_Unit.unit)(body))(function (halogenIO) {
        return bind(liftEffect(Routing_PushState.makeInterface))(function (navInterface) {
            return bind(liftEffect(navInterface.listen(function (location) {
                var route = Routes.parseRoute(location.pathname);
                return Effect_Aff.launchAff_(bind(halogenIO.query(Halogen_Query.mkTell(Kernel.NavigateTo.create(route))))(function () {
                    return pure(Data_Unit.unit);
                }));
            })))(function () {
                return pure(Data_Unit.unit);
            });
        });
    });
}));
export {
    main
};
