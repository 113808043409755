// Generated by purs version 0.15.8
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var OSX = /* #__PURE__ */ (function () {
    function OSX() {

    };
    OSX.value = new OSX();
    return OSX;
})();
var Linux = /* #__PURE__ */ (function () {
    function Linux() {

    };
    Linux.value = new Linux();
    return Linux;
})();
var Windows = /* #__PURE__ */ (function () {
    function Windows() {

    };
    Windows.value = new Windows();
    return Windows;
})();
var Source = /* #__PURE__ */ (function () {
    function Source() {

    };
    Source.value = new Source();
    return Source;
})();
var Select = /* #__PURE__ */ (function () {
    function Select(value0) {
        this.value0 = value0;
    };
    Select.create = function (value0) {
        return new Select(value0);
    };
    return Select;
})();
var latest = "build.108.3de9b15";
var instructions = function (v) {
    if (v instanceof OSX) {
        return Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text("If you use homebrew:"), Halogen_HTML_Elements.pre([ Halogen_HTML_Properties.class_("installation-text-pre") ])([ Halogen_HTML_Core.text("$ brew tap synthesis-labs/galactica\x0a"), Halogen_HTML_Core.text("$ brew install galactica\x0a"), Halogen_HTML_Core.text("$ galactica login\x0a") ]), Halogen_HTML_Core.text("Otherwise download directly from the github release:"), Halogen_HTML_Elements.br([  ]), Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://github.com/synthesis-labs/galactica-cli/releases/download/0.1.0/galactica-x86_64-apple-darwin-0.1.0+" + (latest + ".zip")) ])([ Halogen_HTML_Core.text("galactica-x86_64-apple-darwin-0.1.0+" + (latest + ".zip")) ]) ]);
    };
    if (v instanceof Linux) {
        return Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text("Unfortunately we don't have any pre-packaged binary releases for Linux yet (coming soon!), so your best option is to build from source using Cargo:"), Halogen_HTML_Elements.pre([ Halogen_HTML_Properties.class_("installation-text-pre") ])([ Halogen_HTML_Core.text("$ cargo install --git http://github.com/synthesis-labs/galactica-cli") ]), Halogen_HTML_Core.text("Or download directly from the github release:"), Halogen_HTML_Elements.br([  ]), Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://github.com/synthesis-labs/galactica-cli/releases/download/0.1.0/galactica-x86_64-unknown-linux-gnu-0.1.0+" + (latest + ".zip")) ])([ Halogen_HTML_Core.text("galactica-x86_64-unknown-linux-gnu-0.1.0+" + (latest + ".zip")) ]) ]);
    };
    if (v instanceof Windows) {
        return Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text("If you use scoop:"), Halogen_HTML_Elements.pre([ Halogen_HTML_Properties.class_("installation-text-pre") ])([ Halogen_HTML_Core.text("C:\\> scoop install https://raw.githubusercontent.com/synthesis-labs/galactica-cli/main/galactica.json\x0a"), Halogen_HTML_Core.text("C:\\> galactica login") ]), Halogen_HTML_Core.text("Otherwise download directly from the github release:"), Halogen_HTML_Elements.br([  ]), Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://github.com/synthesis-labs/galactica-cli/releases/download/0.1.0/galactica-x86_64-pc-windows-msvc-0.1.0+" + (latest + ".zip")) ])([ Halogen_HTML_Core.text("galactica-x86_64-pc-windows-msvc-0.1.0+" + (latest + ".zip")) ]) ]);
    };
    if (v instanceof Source) {
        return Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text("Assuming you have a rust toolchain installed:"), Halogen_HTML_Elements.pre([ Halogen_HTML_Properties.class_("installation-text-pre") ])([ Halogen_HTML_Core.text("$ cargo install --git http://github.com/synthesis-labs/galactica-cli") ]), Halogen_HTML_Core.text("Otherwise feel free to browse the github repository:"), Halogen_HTML_Elements.br([  ]), Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://github.com/synthesis-labs/galactica-cli") ])([ Halogen_HTML_Core.text("https://github.com/synthesis-labs/galactica-cli") ]) ]);
    };
    throw new Error("Failed pattern match at Installation (line 63, column 1 - line 63, column 56): " + [ v.constructor.name ]);
};
var initialState = function (input) {
    return {
        selected: input.select
    };
};
var handleAction = function (v) {
    return modify_(function (state) {
        var $12 = {};
        for (var $13 in state) {
            if ({}.hasOwnProperty.call(state, $13)) {
                $12[$13] = state[$13];
            };
        };
        $12.selected = v.value0;
        return $12;
    });
};
var eqInstallation = {
    eq: function (x) {
        return function (y) {
            if (x instanceof OSX && y instanceof OSX) {
                return true;
            };
            if (x instanceof Linux && y instanceof Linux) {
                return true;
            };
            if (x instanceof Windows && y instanceof Windows) {
                return true;
            };
            if (x instanceof Source && y instanceof Source) {
                return true;
            };
            return false;
        };
    }
};
var eq = /* #__PURE__ */ Data_Eq.eq(eqInstallation);
var render = function (v) {
    var os = function (os$prime) {
        return function (name) {
            return function (selected$prime) {
                return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_((function () {
                    if (selected$prime) {
                        return "installation-os selected";
                    };
                    return "installation-os";
                })()), Halogen_HTML_Events.onClick(Data_Function["const"](new Select(os$prime))) ])([ Halogen_HTML_Core.text(name) ]);
            };
        };
    };
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("installation") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("installation-oses") ])([ os(OSX.value)("OSX")(eq(v.selected)(OSX.value)), os(Linux.value)("Linux")(eq(v.selected)(Linux.value)), os(Windows.value)("Windows")(eq(v.selected)(Windows.value)), os(Source.value)("Source")(eq(v.selected)(Source.value)) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("installation-text") ])([ instructions(v.selected) ]) ]);
};
var component = /* #__PURE__ */ (function () {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
})();
var _proxy = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
export {
    latest,
    OSX,
    Linux,
    Windows,
    Source,
    Select,
    _proxy,
    component,
    initialState,
    handleAction,
    render,
    instructions,
    eqInstallation
};
