// Generated by purs version 0.15.8
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Intro from "../Intro/index.js";
import * as RedeemCode from "../RedeemCode/index.js";
import * as Routes from "../Routes/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var slot_ = /* #__PURE__ */ Halogen_HTML.slot_();
var slot_1 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "intro";
    }
})(Data_Ord.ordInt);
var slot_2 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "redeemcode";
    }
})(Data_Ord.ordInt);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var NavigateTo = /* #__PURE__ */ (function () {
    function NavigateTo(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    NavigateTo.create = function (value0) {
        return function (value1) {
            return new NavigateTo(value0, value1);
        };
    };
    return NavigateTo;
})();
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var render = function (dictMonadEffect) {
    return function (dictMonadAff) {
        var MonadEffect0 = dictMonadAff.MonadEffect0();
        var component1 = Intro.component(MonadEffect0);
        var component2 = RedeemCode.component(MonadEffect0)(dictMonadAff);
        return function (v) {
            if (v instanceof Routes.Landing) {
                return slot_1(Intro["_proxy"])(0)(component1)(Data_Unit.unit);
            };
            if (v instanceof Routes.DiscordReturnedCode) {
                return slot_2(RedeemCode["_proxy"])(0)(component2)(Data_Unit.unit);
            };
            return Halogen_HTML_Elements.div([  ])([ Halogen_HTML_Core.text("Other") ]);
        };
    };
};
var initialState = function (v) {
    return Routes.Landing.value;
};
var handleQuery = function (query) {
    return discard(modify_(function (v) {
        return query.value0;
    }))(function () {
        return pure(new Data_Maybe.Just(query.value1));
    });
};
var handleAction = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return function (v) {
        return bind(liftEffect(Routes.currentRoute))(function (route) {
            return modify_(Data_Function["const"](route));
        });
    };
};
var component = function (dictMonadEffect) {
    return function (dictMonadAff) {
        var MonadEffect0 = dictMonadAff.MonadEffect0();
        return Halogen_Component.mkComponent({
            initialState: initialState,
            render: render(MonadEffect0)(dictMonadAff),
            "eval": Halogen_Component.mkEval({
                handleAction: handleAction(MonadEffect0),
                handleQuery: handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                initialize: new Data_Maybe.Just(Initialize.value),
                finalize: Halogen_Component.defaultEval.finalize
            })
        });
    };
};
var _proxy = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
export {
    NavigateTo,
    Initialize,
    _proxy,
    component,
    initialState,
    handleAction,
    handleQuery,
    render
};
