// Generated by purs version 0.15.8
import * as Backend from "../Backend/index.js";
import * as Common from "../Common/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Signin from "../Signin/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var Redeeming = /* #__PURE__ */ (function () {
    function Redeeming() {

    };
    Redeeming.value = new Redeeming();
    return Redeeming;
})();
var Failed = /* #__PURE__ */ (function () {
    function Failed(value0) {
        this.value0 = value0;
    };
    Failed.create = function (value0) {
        return new Failed(value0);
    };
    return Failed;
})();
var Ok = /* #__PURE__ */ (function () {
    function Ok() {

    };
    Ok.value = new Ok();
    return Ok;
})();
var Redeem = /* #__PURE__ */ (function () {
    function Redeem() {

    };
    Redeem.value = new Redeem();
    return Redeem;
})();
var render = function (v) {
    if (v instanceof Failed) {
        return Halogen_HTML_Core.text("Failed with reason: " + v.value0);
    };
    if (v instanceof Redeeming) {
        return Halogen_HTML_Core.text("Redeeming auth code...");
    };
    if (v instanceof Ok) {
        return Halogen_HTML_Core.text("Success!");
    };
    throw new Error("Failed pattern match at RedeemCode (line 55, column 1 - line 55, column 57): " + [ v.constructor.name ]);
};
var handleAction = function (dictMonadEffect) {
    return function (dictMonadAff) {
        var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()));
        var liftAff = Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff));
        return function (v) {
            return bind(liftEffect(Signin.getCodeFromUrl))(function (maybeCode) {
                return bind((function () {
                    if (maybeCode instanceof Data_Maybe.Nothing) {
                        return modify_(Data_Function["const"](new Failed("We didn't get a code from Discord?")));
                    };
                    if (maybeCode instanceof Data_Maybe.Just) {
                        return bind(liftEffect(Signin.baseRedirectUrl))(function (redirectUrl) {
                            return bind(liftAff(Backend.get_token(maybeCode.value0)(redirectUrl)))(function (eitherToken) {
                                if (eitherToken instanceof Data_Either.Left) {
                                    return modify_(Data_Function["const"](new Failed("We failed to get the token: " + Common.printError(eitherToken.value0))));
                                };
                                if (eitherToken instanceof Data_Either.Right) {
                                    return bind(liftEffect(Signin.saveTokenToStorage(eitherToken.value0.token)))(function (success) {
                                        if (success instanceof Data_Either.Left) {
                                            return modify_(Data_Function["const"](new Failed("Failed to save token: " + Common.printError(success.value0))));
                                        };
                                        if (success instanceof Data_Either.Right) {
                                            return modify_(Data_Function["const"](Ok.value));
                                        };
                                        throw new Error("Failed pattern match at RedeemCode (line 49, column 21 - line 52, column 49): " + [ success.constructor.name ]);
                                    });
                                };
                                throw new Error("Failed pattern match at RedeemCode (line 45, column 13 - line 52, column 49): " + [ eitherToken.constructor.name ]);
                            });
                        });
                    };
                    throw new Error("Failed pattern match at RedeemCode (line 39, column 10 - line 52, column 49): " + [ maybeCode.constructor.name ]);
                })())(function () {
                    return pure(Data_Unit.unit);
                });
            });
        };
    };
};
var component = function (dictMonadEffect) {
    return function (dictMonadAff) {
        return Halogen_Component.mkComponent({
            initialState: Data_Function["const"](Redeeming.value),
            render: render,
            "eval": Halogen_Component.mkEval({
                handleAction: handleAction(dictMonadAff.MonadEffect0())(dictMonadAff),
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                initialize: new Data_Maybe.Just(Redeem.value),
                finalize: Halogen_Component.defaultEval.finalize
            })
        });
    };
};
var _proxy = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
export {
    Redeeming,
    Failed,
    Ok,
    Redeem,
    _proxy,
    component,
    handleAction,
    render
};
