// Generated by purs version 0.15.8
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Faqs from "../Faqs/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Installation from "../Installation/index.js";
import * as Signin from "../Signin/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var slot_ = /* #__PURE__ */ Halogen_HTML.slot_();
var slot_1 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "faq";
    }
})(Data_Ord.ordInt);
var slot_2 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "signin";
    }
})(Data_Ord.ordInt);
var slot_3 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "installation";
    }
})(Data_Ord.ordInt);
var State = /* #__PURE__ */ (function () {
    function State() {

    };
    State.value = new State();
    return State;
})();
var Action = /* #__PURE__ */ (function () {
    function Action() {

    };
    Action.value = new Action();
    return Action;
})();
var section = function (name) {
    return function (inner) {
        return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("section") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("section-title") ])([ Halogen_HTML_Core.text(name) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("section-body") ])([ inner ]) ]);
    };
};
var initialState = function (v) {
    return State.value;
};
var handleAction = function (v) {
    return modify_(identity);
};
var faqs = /* #__PURE__ */ (function () {
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("faqs") ])([ slot_1(Faqs["_proxy"])(0)(Faqs.component)(new Data_Tuple.Tuple("Is GalacticAI free?", "Yes totally. GalacticAI makes use of OpenAI and various GPT models, through a commercial API - however we are paying for this access so you don't need to. We may in the future decide to offer a commercial version, which offers potentially more features, and we reserve the right to change our minds should this start to cost us lots of money to maintain!")), slot_1(Faqs["_proxy"])(1)(Faqs.component)(new Data_Tuple.Tuple("Who built GalacticAI?", "GalacticAI was built by the amazing Synthesis Labs team.")), slot_1(Faqs["_proxy"])(2)(Faqs.component)(new Data_Tuple.Tuple("Is GalacticAI open source?", "Yes! The CLI you are looking at is completely open-source, and you are free to mangle it into whatever form you like (please share though!). The backend service which runs the horsepower of GalacticAI is not open source however, and remains our own intellectual property.")), slot_1(Faqs["_proxy"])(3)(Faqs.component)(new Data_Tuple.Tuple("Can I contribute to GalacticAI?", "Sure! Tell all your friends!")), slot_1(Faqs["_proxy"])(4)(Faqs.component)(new Data_Tuple.Tuple("How can I contact you?", "Hit us up on discord at: https://discord.gg/HKnCQ6Q4sX")) ]);
})();
var examples = /* #__PURE__ */ (function () {
    var example = function (cmd) {
        return function (output) {
            return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("example") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("example-cmd") ])([ Halogen_HTML_Core.text("$ " + cmd) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("example-output") ])([ Halogen_HTML_Elements.pre([ Halogen_HTML_Properties.class_("example-output-pre") ])([ Halogen_HTML_Core.text(output) ]) ]) ]);
        };
    };
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("examples") ])([ example("cat src/main.rs | galactica code 'explain what this code does'")("This code is a command line interface (CLI) for interacting with an AI-powered chatbot and generating code based on requirements, with features including login, conversation history, and resetting history."), example("galactica code 'a regex to validate an email address'")("/^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/"), example("cat src/discord_login.rs | galactica code 'provide 2 short suggestions for how to improve this code'")("1. Improve error handling: The current code uses a lot of `unwrap()` calls which can cause the program to crash if an unexpected error occurs. It would be better to use `Result` and `?` to propagate errors and handle them in a more controlled way.") ]);
})();
var render = function (dictMonadEffect) {
    var component1 = Signin.component(dictMonadEffect);
    return function (v) {
        return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("gutters") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("kernel") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("toolbar") ])([ slot_2(Signin["_proxy"])(0)(component1)(Data_Unit.unit) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("heading") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("heading-image") ])([ Halogen_HTML_Elements.img([ Halogen_HTML_Properties.id("heading-image-image"), Halogen_HTML_Properties.src("./img/logo_large_transparent.png") ]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("heading-body") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("heading-body-title") ])([ Halogen_HTML_Core.text("GalacticAI") ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("heading-body-tagline") ])([ Halogen_HTML_Core.text("your co-pilot to galactic conquest") ]) ]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("main") ])([ section("Installation")(slot_3(Installation["_proxy"])(0)(Installation.component)({
            select: Installation.OSX.value
        })), section("Examples")(examples), section("FAQs")(faqs) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("footer") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("synthesis-logo-text") ])([ Halogen_HTML_Core.text("Proudly brought to you by the Labs team at ") ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.id("synthesis-logo") ])([ Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://www.synthesis.co.za") ])([ Halogen_HTML_Elements.img([ Halogen_HTML_Properties.id("synthesis-logo-image"), Halogen_HTML_Properties.src("./img/synthesis_logo.png") ]) ]) ]) ]) ]) ]);
    };
};
var component = function (dictMonadEffect) {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render(dictMonadEffect),
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
var _proxy = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
export {
    State,
    Action,
    _proxy,
    component,
    initialState,
    handleAction,
    render,
    section,
    examples,
    faqs
};
