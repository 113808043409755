// Generated by purs version 0.15.8
import * as $foreign from "./foreign.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Function_Uncurried from "../Data.Function.Uncurried/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
var $$encodeURIComponent = /* #__PURE__ */ (function () {
    return Data_Function_Uncurried.runFn3($foreign["_encodeURIComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
})();
var $$encodeURI = /* #__PURE__ */ (function () {
    return Data_Function_Uncurried.runFn3($foreign["_encodeURI"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
})();
var encodeFormURLComponent = /* #__PURE__ */ (function () {
    return Data_Function_Uncurried.runFn3($foreign["_encodeFormURLComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
})();
var $$decodeURIComponent = /* #__PURE__ */ (function () {
    return Data_Function_Uncurried.runFn3($foreign["_decodeURIComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
})();
var $$decodeURI = /* #__PURE__ */ (function () {
    return Data_Function_Uncurried.runFn3($foreign["_decodeURI"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
})();
var decodeFormURLComponent = /* #__PURE__ */ (function () {
    return Data_Function_Uncurried.runFn3($foreign["_decodeFormURLComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
})();
export {
    $$encodeURIComponent as encodeURIComponent,
    encodeFormURLComponent,
    $$encodeURI as encodeURI,
    $$decodeURIComponent as decodeURIComponent,
    decodeFormURLComponent,
    $$decodeURI as decodeURI
};
