// Generated by purs version 0.15.8
import * as Affjax from "../Affjax/index.js";
import * as Affjax_RequestBody from "../Affjax.RequestBody/index.js";
import * as Affjax_ResponseFormat from "../Affjax.ResponseFormat/index.js";
import * as Affjax_Web from "../Affjax.Web/index.js";
import * as Common from "../Common/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Error from "../Data.Argonaut.Decode.Error/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var gDecodeJsonCons = /* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString));
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "redirect_url";
    }
})())({
    reflectSymbol: function () {
        return "code";
    }
})())());
var unwrap = function (dictDecodeJson) {
    var decodeJson = Data_Argonaut_Decode_Class.decodeJson(dictDecodeJson);
    return function (result) {
        return bind(result)(function (result$prime) {
            return pure((function () {
                if (result$prime instanceof Data_Either.Left) {
                    return new Data_Either.Left(new Common.BackendError(Affjax.printError(result$prime.value0)));
                };
                if (result$prime instanceof Data_Either.Right) {
                    var v = decodeJson(result$prime.value0.body);
                    if (v instanceof Data_Either.Left) {
                        return new Data_Either.Left(new Common.BackendError(Data_Argonaut_Decode_Error.printJsonDecodeError(v.value0)));
                    };
                    if (v instanceof Data_Either.Right) {
                        return new Data_Either.Right(v.value0);
                    };
                    throw new Error("Failed pattern match at Backend (line 41, column 13 - line 44, column 41): " + [ v.constructor.name ]);
                };
                throw new Error("Failed pattern match at Backend (line 37, column 12 - line 44, column 41): " + [ result$prime.constructor.name ]);
            })());
        });
    };
};
var unwrap1 = /* #__PURE__ */ unwrap(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeRecord(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.gDecodeJsonCons(/* #__PURE__ */ Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(/* #__PURE__ */ gDecodeJsonCons(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
    reflectSymbol: function () {
        return "token_type";
    }
})()())({
    reflectSymbol: function () {
        return "scope";
    }
})()())({
    reflectSymbol: function () {
        return "refresh_token";
    }
})()())({
    reflectSymbol: function () {
        return "expires_in";
    }
})()())({
    reflectSymbol: function () {
        return "access_token";
    }
})()())()))(Data_Argonaut_Decode_Class.gDecodeJsonNil)({
    reflectSymbol: function () {
        return "token";
    }
})()())());
var api_base_url = "https://ikmqanq88j.eu-west-1.awsapprunner.com";
var get_token = function (code) {
    return function (redirectUrl) {
        return unwrap1((function () {
            var payload = encodeJson({
                code: code,
                redirect_url: redirectUrl
            });
            return Affjax.post(Affjax_Web.driver)(Affjax_ResponseFormat.json)(api_base_url + "/auth/get_token")(new Data_Maybe.Just(Affjax_RequestBody.json(payload)));
        })());
    };
};
export {
    api_base_url,
    unwrap,
    get_token
};
