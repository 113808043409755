// Generated by purs version 0.15.8
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Record_Unsafe from "../Record.Unsafe/index.js";
import * as Record_Unsafe_Union from "../Record.Unsafe.Union/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var union = function () {
    return function (l) {
        return function (r) {
            return Record_Unsafe_Union.unsafeUnionFn(l, r);
        };
    };
};
var set = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    return function () {
        return function () {
            return function (l) {
                return function (b) {
                    return function (r) {
                        return Record_Unsafe.unsafeSet(reflectSymbol(l))(b)(r);
                    };
                };
            };
        };
    };
};
var nub = function () {
    return Unsafe_Coerce.unsafeCoerce;
};
var merge = function () {
    return function () {
        return function (l) {
            return function (r) {
                return Record_Unsafe_Union.unsafeUnionFn(l, r);
            };
        };
    };
};
var insert = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    return function () {
        return function () {
            return function (l) {
                return function (a) {
                    return function (r) {
                        return Record_Unsafe.unsafeSet(reflectSymbol(l))(a)(r);
                    };
                };
            };
        };
    };
};
var get = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    return function () {
        return function (l) {
            return function (r) {
                return Record_Unsafe.unsafeGet(reflectSymbol(l))(r);
            };
        };
    };
};
var modify = function (dictIsSymbol) {
    var set1 = set(dictIsSymbol)()();
    var get1 = get(dictIsSymbol)();
    return function () {
        return function () {
            return function (l) {
                return function (f) {
                    return function (r) {
                        return set1(l)(f(get1(l)(r)))(r);
                    };
                };
            };
        };
    };
};
var equalFieldsNil = {
    equalFields: function (v) {
        return function (v1) {
            return function (v2) {
                return true;
            };
        };
    }
};
var equalFields = function (dict) {
    return dict.equalFields;
};
var equalFieldsCons = function (dictIsSymbol) {
    var get1 = get(dictIsSymbol)();
    return function (dictEq) {
        var eq = Data_Eq.eq(dictEq);
        return function () {
            return function (dictEqualFields) {
                var equalFields1 = equalFields(dictEqualFields);
                return {
                    equalFields: function (v) {
                        return function (a) {
                            return function (b) {
                                var get$prime = get1(Type_Proxy["Proxy"].value);
                                var equalRest = equalFields1(Type_Proxy["Proxy"].value);
                                return eq(get$prime(a))(get$prime(b)) && equalRest(a)(b);
                            };
                        };
                    }
                };
            };
        };
    };
};
var equal = function () {
    return function (dictEqualFields) {
        var equalFields1 = equalFields(dictEqualFields);
        return function (a) {
            return function (b) {
                return equalFields1(Type_Proxy["Proxy"].value)(a)(b);
            };
        };
    };
};
var disjointUnion = function () {
    return function () {
        return function (l) {
            return function (r) {
                return Record_Unsafe_Union.unsafeUnionFn(l, r);
            };
        };
    };
};
var $$delete = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    return function () {
        return function () {
            return function (l) {
                return function (r) {
                    return Record_Unsafe.unsafeDelete(reflectSymbol(l))(r);
                };
            };
        };
    };
};
var rename = function (dictIsSymbol) {
    var get1 = get(dictIsSymbol)();
    var delete1 = $$delete(dictIsSymbol)()();
    return function (dictIsSymbol1) {
        var insert1 = insert(dictIsSymbol1)()();
        return function () {
            return function () {
                return function () {
                    return function () {
                        return function (prev) {
                            return function (next) {
                                return function (record) {
                                    return insert1(next)(get1(prev)(record))(delete1(prev)(record));
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
export {
    get,
    set,
    modify,
    insert,
    $$delete as delete,
    rename,
    equal,
    merge,
    union,
    disjointUnion,
    nub,
    equalFields,
    equalFieldsCons,
    equalFieldsNil
};
