// Generated by purs version 0.15.8
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Effect from "../Effect/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Generic from "../Routing.Duplex.Generic/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Location from "../Web.HTML.Location/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var bind = /* #__PURE__ */ Control_Bind.bind(Effect.bindEffect);
var Test = /* #__PURE__ */ (function () {
    function Test() {

    };
    Test.value = new Test();
    return Test;
})();
var Landing = /* #__PURE__ */ (function () {
    function Landing() {

    };
    Landing.value = new Landing();
    return Landing;
})();
var DiscordReturnedCode = /* #__PURE__ */ (function () {
    function DiscordReturnedCode() {

    };
    DiscordReturnedCode.value = new DiscordReturnedCode();
    return DiscordReturnedCode;
})();
var NotFound = /* #__PURE__ */ (function () {
    function NotFound() {

    };
    NotFound.value = new NotFound();
    return NotFound;
})();
var genericRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Test.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Landing.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return DiscordReturnedCode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return NotFound.value;
        };
        throw new Error("Failed pattern match at Routes (line 20, column 1 - line 20, column 47): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Test) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Landing) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof DiscordReturnedCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof NotFound) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at Routes (line 20, column 1 - line 20, column 47): " + [ x.constructor.name ]);
    }
};
var routes = /* #__PURE__ */ Routing_Duplex.root(/* #__PURE__ */ Routing_Duplex_Generic.sum(genericRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Test";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Landing";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "DiscordReturnedCode";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "NotFound";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments)))))({
    Test: /* #__PURE__ */ Routing_Duplex.path("test")(Routing_Duplex_Generic.noArgs),
    Landing: Routing_Duplex_Generic.noArgs,
    DiscordReturnedCode: /* #__PURE__ */ Routing_Duplex.path("redeem")(Routing_Duplex_Generic.noArgs),
    NotFound: /* #__PURE__ */ Routing_Duplex.path("notfound")(Routing_Duplex_Generic.noArgs)
}));
var parseRoute = function (pathname) {
    return Data_Either.either(function (v) {
        return NotFound.value;
    })(identity)(Routing_Duplex.parse(routes)(pathname));
};
var currentRoute = /* #__PURE__ */ Data_Functor.mapFlipped(Effect.functorEffect)(/* #__PURE__ */ bind(/* #__PURE__ */ bind(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.pathname))(parseRoute);
export {
    Test,
    Landing,
    DiscordReturnedCode,
    NotFound,
    routes,
    parseRoute,
    currentRoute,
    genericRoute
};
