// Generated by purs version 0.15.8
var paste = "paste";
var cut = "cut";
var copy = "copy";
var clipboardchange = "clipboardchange";
export {
    clipboardchange,
    copy,
    cut,
    paste
};
