// Generated by purs version 0.15.8
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Show from "../Data.Show/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var ResponseHeader = /* #__PURE__ */ (function () {
    function ResponseHeader(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ResponseHeader.create = function (value0) {
        return function (value1) {
            return new ResponseHeader(value0, value1);
        };
    };
    return ResponseHeader;
})();
var value = function (v) {
    return v.value1;
};
var showResponseHeader = {
    show: function (v) {
        return "(ResponseHeader " + (show(v.value0) + (" " + (show(v.value1) + ")")));
    }
};
var name = function (v) {
    return v.value0;
};
var eqResponseHeader = {
    eq: function (x) {
        return function (y) {
            return x.value0 === y.value0 && x.value1 === y.value1;
        };
    }
};
var ordResponseHeader = {
    compare: function (x) {
        return function (y) {
            var v = compare(x.value0)(y.value0);
            if (v instanceof Data_Ordering.LT) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof Data_Ordering.GT) {
                return Data_Ordering.GT.value;
            };
            return compare(x.value1)(y.value1);
        };
    },
    Eq0: function () {
        return eqResponseHeader;
    }
};
export {
    ResponseHeader,
    name,
    value,
    eqResponseHeader,
    ordResponseHeader,
    showResponseHeader
};
