// Generated by purs version 0.15.8
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var Toggle = /* #__PURE__ */ (function () {
    function Toggle() {

    };
    Toggle.value = new Toggle();
    return Toggle;
})();
var render = function (v) {
    var visible = (function () {
        if (v.value1) {
            return Halogen_HTML_Properties.classes([ "faq-answer" ]);
        };
        return Halogen_HTML_Properties.classes([ "faq-answer", "hidden" ]);
    })();
    return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("faq") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("faq-question"), Halogen_HTML_Events.onClick(Data_Function["const"](Toggle.value)) ])([ Halogen_HTML_Core.text(v.value0.value0) ]), Halogen_HTML_Elements.div([ visible ])([ Halogen_HTML_Core.text(v.value0.value1) ]) ]);
};
var initialState = /* #__PURE__ */ (function () {
    return Data_Function.flip(Data_Tuple.Tuple.create)(false);
})();
var handleAction = function (v) {
    return modify_(function (v1) {
        return new Data_Tuple.Tuple(v1.value0, !v1.value1);
    });
};
var component = /* #__PURE__ */ (function () {
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction,
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
})();
var _proxy = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
export {
    Toggle,
    _proxy,
    component,
    initialState,
    handleAction,
    render
};
