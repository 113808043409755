// Generated by purs version 0.15.8
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
var V = function (x) {
    return x;
};
var validation = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof Data_Either.Left) {
                return v(v2.value0);
            };
            if (v2 instanceof Data_Either.Right) {
                return v1(v2.value0);
            };
            throw new Error("Failed pattern match at Data.Validation.Semiring (line 49, column 1 - line 49, column 84): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var toEither = function (v) {
    return v;
};
var showV = function (dictShow) {
    var show = Data_Show.show(dictShow);
    return function (dictShow1) {
        var show1 = Data_Show.show(dictShow1);
        return {
            show: function (v) {
                if (v instanceof Data_Either.Left) {
                    return "invalid (" + (show(v.value0) + ")");
                };
                if (v instanceof Data_Either.Right) {
                    return "pure (" + (show1(v.value0) + ")");
                };
                throw new Error("Failed pattern match at Data.Validation.Semiring (line 82, column 10 - line 84, column 55): " + [ v.constructor.name ]);
            }
        };
    };
};
var newtypeV = {
    Coercible0: function () {
        return undefined;
    }
};
var isValid = function (v) {
    if (v instanceof Data_Either.Right) {
        return true;
    };
    return false;
};
var invalid = function ($113) {
    return V(Data_Either.Left.create($113));
};
var functorV = Data_Either.functorEither;
var foldableV = {
    foldMap: function (dictMonoid) {
        return validation(Data_Function["const"](Data_Monoid.mempty(dictMonoid)));
    },
    foldr: function (f) {
        return function (b) {
            return validation(Data_Function["const"](b))(Data_Function.flip(f)(b));
        };
    },
    foldl: function (f) {
        return function (b) {
            return validation(Data_Function["const"](b))(f(b));
        };
    }
};
var traversableV = {
    sequence: function (dictApplicative) {
        return validation((function () {
            var $114 = Control_Applicative.pure(dictApplicative);
            return function ($115) {
                return $114(V(Data_Either.Left.create($115)));
            };
        })())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(function ($116) {
            return V(Data_Either.Right.create($116));
        }));
    },
    traverse: function (dictApplicative) {
        var pure = Control_Applicative.pure(dictApplicative);
        var map = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        return function (f) {
            return validation(function ($117) {
                return pure(V(Data_Either.Left.create($117)));
            })((function () {
                var $118 = map(function ($120) {
                    return V(Data_Either.Right.create($120));
                });
                return function ($119) {
                    return $118(f($119));
                };
            })());
        };
    },
    Functor0: function () {
        return functorV;
    },
    Foldable1: function () {
        return foldableV;
    }
};
var eqV = function (dictEq) {
    var eqEither = Data_Either.eqEither(dictEq);
    return function (dictEq1) {
        var eq = Data_Eq.eq(eqEither(dictEq1));
        return {
            eq: function (x) {
                return function (y) {
                    return eq(x)(y);
                };
            }
        };
    };
};
var ordV = function (dictOrd) {
    var ordEither = Data_Either.ordEither(dictOrd);
    var eqV1 = eqV(dictOrd.Eq0());
    return function (dictOrd1) {
        var compare = Data_Ord.compare(ordEither(dictOrd1));
        var eqV2 = eqV1(dictOrd1.Eq0());
        return {
            compare: function (x) {
                return function (y) {
                    return compare(x)(y);
                };
            },
            Eq0: function () {
                return eqV2;
            }
        };
    };
};
var eq1V = function (dictEq) {
    var eqV1 = eqV(dictEq);
    return {
        eq1: function (dictEq1) {
            return Data_Eq.eq(eqV1(dictEq1));
        }
    };
};
var ord1V = function (dictOrd) {
    var ordV1 = ordV(dictOrd);
    var eq1V1 = eq1V(dictOrd.Eq0());
    return {
        compare1: function (dictOrd1) {
            return Data_Ord.compare(ordV1(dictOrd1));
        },
        Eq10: function () {
            return eq1V1;
        }
    };
};
var bifunctorV = Data_Bifunctor.bifunctorEither;
var applyV = function (dictSemiring) {
    var mul = Data_Semiring.mul(dictSemiring);
    return {
        apply: function (v) {
            return function (v1) {
                if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(mul(v.value0)(v1.value0));
                };
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left(v.value0);
                };
                if (v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(v1.value0);
                };
                if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                    return new Data_Either.Right(v.value0(v1.value0));
                };
                throw new Error("Failed pattern match at Data.Validation.Semiring (line 90, column 1 - line 94, column 54): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        Functor0: function () {
            return functorV;
        }
    };
};
var semigroupV = function (dictSemiring) {
    var lift2 = Control_Apply.lift2(applyV(dictSemiring));
    return function (dictSemigroup) {
        return {
            append: lift2(Data_Semigroup.append(dictSemigroup))
        };
    };
};
var applicativeV = function (dictSemiring) {
    var applyV1 = applyV(dictSemiring);
    return {
        pure: function ($121) {
            return V(Data_Either.Right.create($121));
        },
        Apply0: function () {
            return applyV1;
        }
    };
};
var monoidV = function (dictSemiring) {
    var pure = Control_Applicative.pure(applicativeV(dictSemiring));
    var semigroupV1 = semigroupV(dictSemiring);
    return function (dictMonoid) {
        var semigroupV2 = semigroupV1(dictMonoid.Semigroup0());
        return {
            mempty: pure(Data_Monoid.mempty(dictMonoid)),
            Semigroup0: function () {
                return semigroupV2;
            }
        };
    };
};
var andThen = function (v1) {
    return function (f) {
        return validation(invalid)(f)(v1);
    };
};
var altV = function (dictSemiring) {
    var add = Data_Semiring.add(dictSemiring);
    return {
        alt: function (v) {
            return function (v1) {
                if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(add(v.value0)(v1.value0));
                };
                if (v instanceof Data_Either.Left) {
                    return v1;
                };
                if (v instanceof Data_Either.Right) {
                    return new Data_Either.Right(v.value0);
                };
                throw new Error("Failed pattern match at Data.Validation.Semiring (line 105, column 1 - line 108, column 36): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        Functor0: function () {
            return functorV;
        }
    };
};
var plusV = function (dictSemiring) {
    var altV1 = altV(dictSemiring);
    return {
        empty: new Data_Either.Left(Data_Semiring.zero(dictSemiring)),
        Alt0: function () {
            return altV1;
        }
    };
};
export {
    V,
    validation,
    invalid,
    isValid,
    toEither,
    andThen,
    newtypeV,
    eqV,
    eq1V,
    ordV,
    ord1V,
    showV,
    functorV,
    bifunctorV,
    applyV,
    applicativeV,
    semigroupV,
    monoidV,
    altV,
    plusV,
    foldableV,
    traversableV
};
